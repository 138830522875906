import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import "./BlogDetail.css";

const BlogDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, "latestExhibition", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPost(docSnap.data());
      }
    };
    fetchPost();
  }, [id]);

  if (!post)
    return (
      <div className="loading-message">
        <p>Cargando...</p>
      </div>
    );

  return (
    <div className="blog-detail">
      <div className="container">
        {/* Título */}
        <h1>{post.title}</h1>

        {/* Subtítulo */}
        <h2>{post.subtitle}</h2>

        {/* Copete */}
        <p className="copete">{post.copete}</p>

        {/* Imagen */}
        <img src={post.image} alt={post.title} />

        {/* Cuerpo */}
        <div className="body">{post.body}</div>
      </div>
    </div>
  );
};

export default BlogDetail;
