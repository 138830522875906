import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import "./Footer.css";

const Footer = ({ isAuthenticated }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        localStorage.removeItem("isAuthenticated");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error al cerrar sesión:", error);
      });
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Información de la escultora */}
        <div className="footer-about">
          <h4>Carina Fabaro</h4>
          <p>
            Escultora apasionada por el arte contemporáneo. Cada obra es una
            expresión única de creatividad y emociones.
          </p>
        </div>

        {/* Redes Sociales */}
        <div className="footer-social">
          <h4>Sígueme</h4>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>

        {/* Contacto */}
        <div className="footer-contact">
          <h4>Contacto</h4>
          <p>Email: carina.fabaro@gmail.com</p>
          <p>Teléfono: +54 11 1234 5678</p>
        </div>

        {/* Botón de Iniciar/Cerrar Sesión */}
        <div className="footer-auth">
          {!isAuthenticated ? (
            <button
              className="btn btn-primary login-button"
              onClick={() => navigate("/login")}
            >
              Iniciar Sesión
            </button>
          ) : (
            <button
              className="btn btn-danger login-button"
              onClick={handleLogout}
            >
              Cerrar Sesión
            </button>
          )}
        </div>

        {/* Menú de Administración */}
        {isAuthenticated && (
          <div className="footer-admin">
            <h4>Administración</h4>
            <ul className="admin-menu">
              <li>
                <Link to="/admin/add-artwork">Agregar Obras</Link>
              </li>
              <li>
                <Link to="/admin/blog">Blog</Link>
              </li>
              <li>
                <Link to="/admin/add-experience">Experiencias</Link>
              </li>
              <li>
                <Link to="/admin/upload-3d">Cargar Archivos 3D</Link>
              </li>

              <li>
                <Link to="/admin/new-arrivals">Agregar Lo Nuevo</Link>
              </li>

            </ul>
          </div>
        )}
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Carina Fabaro. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
