import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import "./NewArrivalDetails.css";

const NewArrivalDetails = () => {
  const { id } = useParams();
  const [newArrival, setNewArrival] = useState(null);

  useEffect(() => {
    const fetchNewArrival = async () => {
      try {
        const docRef = doc(db, "newArrivals", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setNewArrival(docSnap.data());
        } else {
          console.error("No se encontró el documento");
        }
      } catch (error) {
        console.error("Error al obtener los detalles:", error);
      }
    };

    fetchNewArrival();
  }, [id]);

  if (!newArrival) {
    return <p>Cargando detalles...</p>;
  }

  return (
    <div className="new-arrival-details">
      <h1>{newArrival.title}</h1>
      <img src={newArrival.image} alt={newArrival.title} className="detail-image" />
      <p><strong>Material:</strong> {newArrival.material}</p>
      <p><strong>Descripción:</strong> {newArrival.description}</p>
      <p><strong>Categoría:</strong> {newArrival.category}</p>
      <p><strong>Precio:</strong> ${newArrival.price}</p>
      {/* {newArrival.highlight && <p><strong>Destacado:</strong> Sí</p>} */}
    </div>
  );
};

export default NewArrivalDetails;
