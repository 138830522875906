import React, { useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import "./ThreeDViewerPage.css";

const ThreeDViewerPage = () => {
  const [models, setModels] = useState([]);
  const [selectedModelUrl, setSelectedModelUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  // Obtener modelos desde Firestore
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const modelsCollection = collection(db, "3DModels");
        const snapshot = await getDocs(modelsCollection);
        const modelList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setModels(modelList);
      } catch (error) {
        console.error("Error al obtener modelos de Firestore:", error);
      }
    };

    fetchModels();
  }, []);

  // Validar que la URL es accesible
  const validateModelUrl = async (url) => {
    try {
      const response = await fetch(url, { method: "HEAD" });
      if (!response.ok) throw new Error(`HTTP Error: ${response.status}`);
      return true;
    } catch (error) {
      console.error("Error validando la URL del modelo:", error);
      return false;
    }
  };

  // Cargar el modelo seleccionado
  useEffect(() => {
    if (!selectedModelUrl) return;

    const loadModel = async () => {
      const isValid = await validateModelUrl(selectedModelUrl);
      if (!isValid) {
        setErrorMessage("La URL del modelo no es válida o no es accesible.");
        return;
      }

      const container = document.getElementById("three-viewer");
      if (!container) {
        setErrorMessage("Error: Contenedor del visor 3D no encontrado.");
        return;
      }

      // Configuración básica de THREE.js
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        container.offsetWidth / container.offsetHeight,
        0.1,
        1000
      );
      camera.position.set(0, 2, 5);

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      container.innerHTML = "";
      container.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;

      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.set(5, 5, 5);
      scene.add(light);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      // Identificar la extensión del archivo
      const getFileExtension = (url) => {
        return url.split("?")[0].split(".").pop().toLowerCase();
      };

      const fileExtension = getFileExtension(selectedModelUrl);
      let loader;

      if (fileExtension === "glb" || fileExtension === "gltf") {
        loader = new GLTFLoader();
      } else if (fileExtension === "obj") {
        loader = new OBJLoader();
      } else if (fileExtension === "fbx") {
        loader = new FBXLoader();
      } else {
        setErrorMessage(`Error: El formato .${fileExtension} no es compatible.`);
        return;
      }

      // Cargar el modelo con una barra de progreso
      loader.load(
        selectedModelUrl,
        (model) => {
          setErrorMessage(""); // Limpiar errores previos
          const loadedModel = model.scene || model;

          // Calcular el tamaño del modelo
          const boundingBox = new THREE.Box3().setFromObject(loadedModel);
          const size = new THREE.Vector3();
          boundingBox.getSize(size);

          console.log("Dimensiones del modelo:", size);

          // Ajustar escala del modelo
          const maxDimension = Math.max(size.x, size.y, size.z);
          const scaleFactor = 1 / maxDimension; // Escala proporcional al tamaño
          loadedModel.scale.set(scaleFactor, scaleFactor, scaleFactor);

          // Centrar el modelo
          const center = new THREE.Vector3();
          boundingBox.getCenter(center);
          loadedModel.position.sub(center);

          // Agregar el modelo a la escena
          scene.add(loadedModel);

          // Animación de renderizado
          const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
          };

          animate();
          setProgress(100); // Carga completa
        },
        (xhr) => {
          // Actualizar progreso de carga
          const percentComplete = Math.round((xhr.loaded / xhr.total) * 100);
          setProgress(percentComplete);
        },
        (error) => {
          console.error("Error al cargar el modelo:", error);
          setErrorMessage("Error al cargar el modelo. Verifica la URL o el formato.");
          setProgress(0); // Restablecer progreso en caso de error
        }
      );

      // Manejar redimensionamiento del visor
      const handleResize = () => {
        camera.aspect = container.offsetWidth / container.offsetHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(container.offsetWidth, container.offsetHeight);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        container.innerHTML = "";
        renderer.dispose();
      };
    };

    loadModel();
  }, [selectedModelUrl]);

  return (
    <div className="three-d-viewer-page">
      <h2>Visor 3D</h2>
      <div className="viewer-container">
        <div className="model-list">
          <h3>Modelos disponibles:</h3>
          <ul>
            {models.map((model) => (
              <li key={model.id}>
                <button onClick={() => setSelectedModelUrl(model.modelUrl)}>{model.name}</button>
              </li>
            ))}
          </ul>
        </div>
        <div id="three-viewer" className="three-viewer">
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {!selectedModelUrl && <p>Selecciona un modelo para comenzar.</p>}
          {progress > 0 && progress < 100 && (
            <div className="progress-bar">
              <div style={{ width: `${progress}%` }}>{progress}%</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThreeDViewerPage;
