import React, { useState, useEffect } from "react";
import Swal from "sweetalert2"; // Importa SweetAlert2
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, getDocs, addDoc, doc, deleteDoc } from "firebase/firestore";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { db, storage } from "../firebaseConfig";
import "./Upload3DModel.css";

const ModelPreview = ({ url, format }) => {
  const [model, setModel] = useState(null);

  useEffect(() => {
    let loader;

    if (format === "glb" || format === "gltf") {
      loader = new GLTFLoader();
    } else if (format === "fbx") {
      loader = new FBXLoader();
    } else if (format === "obj") {
      loader = new OBJLoader();
    } else {
      Swal.fire("Error", "Formato no soportado", "error");
      return;
    }

    loader.load(
      url,
      (loadedModel) => {
        setModel(format === "glb" || format === "gltf" ? loadedModel.scene : loadedModel);
      },
      undefined,
      (error) => Swal.fire("Error", `Error cargando modelo: ${error.message}`, "error")
    );
  }, [url, format]);

  return model ? <primitive object={model} /> : null;
};

const Upload3DModel = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [models, setModels] = useState([]);
 // const [fileError, setFileError] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileFormat, setFileFormat] = useState(null);

  const allowedExtensions = [".glb", ".gltf", ".fbx", ".obj"];

  // Fetch models from Firestore
  const fetchModels = async () => {
    try {
      const modelsCollection = collection(db, "3DModels");
      const snapshot = await getDocs(modelsCollection);
      const modelList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setModels(modelList);
    } catch (error) {
      Swal.fire("Error", "Error al obtener los modelos: " + error.message, "error");
    }
  };

  useEffect(() => {
    fetchModels();
  }, []);

  const handleUpload = (e) => {
    e.preventDefault();

    if (!file) {
      Swal.fire("Error", "Por favor, selecciona un archivo para cargar.", "error");
      return;
    }

    const fileExtension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      Swal.fire("Error", `El formato de archivo no es válido. Permitidos: ${allowedExtensions.join(", ")}`, "error");
      return;
    }

    const storageRef = ref(storage, `3DModels/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        Swal.fire("Error", `Error al cargar el archivo: ${error.message}`, "error");
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(storageRef);
          await addDoc(collection(db, "3DModels"), {
            name: file.name,
            modelUrl: downloadURL,
            format: fileExtension.replace(".", ""),
          });
          Swal.fire("Éxito", "Modelo cargado exitosamente.", "success");
          setFile(null);
          setProgress(0);
          setPreviewUrl(null);
          setFileFormat(null);
          fetchModels();
        } catch (error) {
          Swal.fire("Error", `Error al guardar en Firestore: ${error.message}`, "error");
        }
      }
    );
  };

  const handleDelete = async (id, fileName) => {
    const confirm = await Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción no se puede deshacer.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    });

    if (confirm.isConfirmed) {
      const modelRef = doc(db, "3DModels", id);
      const fileRef = ref(storage, `3DModels/${fileName}`);
      try {
        await deleteObject(fileRef);
        await deleteDoc(modelRef);
        setModels(models.filter((model) => model.id !== id));
        Swal.fire("Éxito", "Modelo eliminado exitosamente.", "success");
      } catch (error) {
        Swal.fire("Error", `Error al eliminar el modelo: ${error.message}`, "error");
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileExtension = selectedFile.name.slice(selectedFile.name.lastIndexOf(".")).toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      Swal.fire("Error", `El formato de archivo no es válido. Permitidos: ${allowedExtensions.join(", ")}`, "error");
      return;
    }

    setFile(selectedFile);
    setFileFormat(fileExtension.replace(".", ""));

    // Crear URL de previsualización
    const url = URL.createObjectURL(selectedFile);
    setPreviewUrl(url);
  };

  return (
    <div className="upload-3d-model">
      <h2>Gestión de Modelos 3D</h2>

      {/* Formulario de carga */}
      <form onSubmit={handleUpload}>
        <input type="file" accept={allowedExtensions.join(",")} onChange={handleFileChange} required />
        {progress > 0 && <p>Progreso de carga: {Math.round(progress)}%</p>}
        <button type="submit">Cargar Modelo</button>
      </form>

      {/* Previsualización del modelo */}
      <div className="preview-container">
        {previewUrl && fileFormat && (
          <Canvas>
            <OrbitControls />
            <ambientLight intensity={0.5} />
            <directionalLight position={[5, 5, 5]} />
            <ModelPreview url={previewUrl} format={fileFormat} />
          </Canvas>
        )}
      </div>

      {/* Tabla de Modelos */}
      <table className="model-table">
        <thead>
          <tr>
            <th>Nombre del Modelo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {models.map((model) => (
            <tr key={model.id}>
              <td>{model.name}</td>
              <td>
                <button onClick={() => handleDelete(model.id, model.name)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Upload3DModel;
