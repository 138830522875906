import React, { useState, useEffect } from "react";
import { db, storage } from "../firebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";
import "./NewArrivals.css";

const NewArrivals = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [material, setMaterial] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [highlight, setHighlight] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [newArrivals, setNewArrivals] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const fetchNewArrivals = async () => {
    const querySnapshot = await getDocs(collection(db, "newArrivals"));
    const arrivals = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setNewArrivals(arrivals);
  };

  useEffect(() => {
    fetchNewArrivals();
  }, []);

  const handleImageUpload = async (file) => {
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const uploadedImageUrl = image
        ? await handleImageUpload(image)
        : imageUrl;

      if (isEditing) {
        await updateDoc(doc(db, "newArrivals", currentId), {
          title,
          description,
          material,
          image: uploadedImageUrl,
          category,
          price: price ? parseFloat(price) : 0,
          highlight,
        });
        setSuccessMessage("¡Elemento actualizado exitosamente!");
      } else {
        await addDoc(collection(db, "newArrivals"), {
          title,
          description,
          material,
          image: uploadedImageUrl,
          category,
          price: price ? parseFloat(price) : 0,
          highlight,
          publishedDate: Timestamp.now(),
        });
        setSuccessMessage("¡Nuevo elemento agregado exitosamente!");
      }

      fetchNewArrivals();
      resetForm();
    } catch (error) {
      console.error("Error al guardar el elemento:", error);
      setSuccessMessage("Ocurrió un error. Inténtalo nuevamente.");
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "newArrivals", id));
      fetchNewArrivals();
    } catch (error) {
      console.error("Error al eliminar elemento:", error);
    }
  };

  const handleEdit = (item) => {
    setTitle(item.title);
    setDescription(item.description);
    setMaterial(item.material);
    setImageUrl(item.image);
    setCategory(item.category);
    setPrice(item.price);
    setHighlight(item.highlight);
    setIsEditing(true);
    setCurrentId(item.id);
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setMaterial("");
    setImage(null);
    setImageUrl("");
    setCategory("");
    setPrice("");
    setHighlight(false);
    setIsEditing(false);
    setCurrentId(null);
  };

  return (
    <div className="new-arrivals">
      <h1>Lo Nuevo</h1>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-group">
          <label htmlFor="title">Título</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Descripción</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="material">Material</label>
          <input
            type="text"
            id="material"
            value={material}
            onChange={(e) => setMaterial(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Imagen</label>
          <input
            type="file"
            id="image"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Categoría</label>
          <input
            type="text"
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="price">Precio</label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="highlight">Destacado</label>
          <input
            type="checkbox"
            id="highlight"
            checked={highlight}
            onChange={(e) => setHighlight(e.target.checked)}
          />
        </div>
        <button type="submit">
          {isEditing ? "Actualizar" : "Agregar"}
        </button>
        {isEditing && (
          <button type="button" onClick={resetForm}>
            Cancelar
          </button>
        )}
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}

      <table className="artwork-grid">
        <thead>
          {/* <tr>
            <th>Imagen</th>
            <th>Título</th>
            <th>Material</th>
            <th>Categoría</th>
            <th>Precio</th>
            <th>Acciones</th>
          </tr> */}
        </thead>
        <tbody>
          {newArrivals.map((item) => (
            <tr key={item.id}>
              <td>
                <img src={item.image} alt={item.title} />
              </td>
              <td>
                <Link to={`/new-arrival/${item.id}`} className="artwork-title">
                  {item.title}
                </Link>
              </td>
              <td>{item.material}</td>
              <td>{item.category}</td>
              <td>${item.price}</td>
              <td>
                <div className="action-buttons">
                  <button
                    className="edit"
                    onClick={() => handleEdit(item)}
                  >
                    Modificar
                  </button>
                  <button
                    className="delete"
                    onClick={() => handleDelete(item.id)}
                  >
                    Eliminar
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewArrivals;

