import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
//import ThreeDViewer from "./ThreeDViewer";
import ArtworkCard from "../components/ArtworkCard";
import "./Gallery.css";

const Gallery = () => {
  const [/* models */, setModels] = useState([]);
  const [artworks, setArtworks] = useState([]);
  //const [selectedModelUrl, setSelectedModelUrl] = useState(null);

  // Fetch Models and Artworks from Firestore
  useEffect(() => {
    const fetchModelsAndArtworks = async () => {
      // Fetch Models
      const modelsCollection = collection(db, "3DModels");
      const modelsSnapshot = await getDocs(modelsCollection);
      const modelList = modelsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setModels(modelList);

      // Fetch Artworks
      const artworksCollection = collection(db, "artworks");
      const artworksSnapshot = await getDocs(artworksCollection);
      const artworksList = artworksSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArtworks(artworksList);
    };

    fetchModelsAndArtworks();
  }, []);

  return (
    <div className="gallery">
      <h2>Galería</h2>

      {/* {/* Tabla de Modelos Cargados */}
      {/* <div className="model-list">
        <h3>Modelos Cargados</h3>
        <table className="model-table">
          <thead>
            <tr>
              <th>Nombre del Archivo</th>
            </tr>
          </thead>
          <tbody>
            {models.map((model) => (
              <tr key={model.id}>
                <td>{model.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>  */}

      {/* Lista de Obras */}
      <div className="artwork-grid">
        <h3>Obras de Arte</h3>
        {artworks.map((artwork) => (
          <div key={artwork.id} className="artwork-card">
            <ArtworkCard artwork={artwork} />
          </div>
        ))}
      </div>

      {/* {/* Visor 3D */}
      {/* {selectedModelUrl && (
        <div className="viewer-container">
          <h3>Visor 3D</h3>
          <ThreeDViewer modelUrl={selectedModelUrl} />
        </div>
      )}  */}
    </div>
  );
};

export default Gallery;
