import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Componentes principales
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import ThreeDViewerPage from "./pages/ThreeDViewerPage";
import NewArrivalDetails from "./components/NewArrivalDetails";

// Componentes administrativos
import AdminPanel from "./components/AdminPanel";
import AddArtwork from "./components/AddArtwork";
import Blog from "./components/Blog";
import AddExperience from "./components/AddExperience";
import Upload3DModel from "./components/Upload3DModel";
import AddNewArrival from "./components/AddNewArrival";

// Detalles
import ArtworkDetail from "./components/ArtworkDetail";
import BlogDetail from "./components/BlogDetail";

// Autenticación
import Login from "./components/Login";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Header isAuthenticated={isAuthenticated} />
      <main>
        <Routes>
          {/* Rutas públicas */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/artwork/:id" element={<ArtworkDetail />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/3d-viewer" element={<ThreeDViewerPage />} />
          <Route path="/new/:id" element={<NewArrivalDetails />} />

          {/* Rutas protegidas para administración */}
          <Route
            path="/admin"
            element={
              isAuthenticated ? <AdminPanel /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/add-artwork"
            element={
              isAuthenticated ? <AddArtwork /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/blog"
            element={
              isAuthenticated ? <Blog /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/add-experience"
            element={
              isAuthenticated ? <AddExperience /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/upload-3d"
            element={
              isAuthenticated ? <Upload3DModel /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/new-arrivals"
            element={
              isAuthenticated ? <AddNewArrival /> : <Navigate to="/login" replace />
            }
          />
        </Routes>
      </main>
      <Footer isAuthenticated={isAuthenticated} />
    </Router>
  );
};

export default App;
