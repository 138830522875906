import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBY0JDmG66cKhQUIDiy-Do_1iGL2rMD8SE",
  authDomain: "fabaro-2cb1e.firebaseapp.com",
  projectId: "fabaro-2cb1e",
  storageBucket: "fabaro-2cb1e.appspot.com",
  messagingSenderId: "92208889404",
  appId: "1:92208889404:web:2baf8daa49092c00284d39"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
export const auth = getAuth(app);
export const storage = getStorage(app);