import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Swal from "sweetalert2";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      Swal.fire("Éxito", "Acceso concedido.", "success");
      localStorage.setItem("isAuthenticated", "true"); // Guardar estado de autenticación
      localStorage.setItem("userEmail", user.email); // Guardar información adicional
      navigate("/admin/add-artwork"); // Redirigir al panel de administración
    } catch (error) {
      console.error("Error en el inicio de sesión:", error.message);
      Swal.fire("Error", "Correo electrónico o contraseña incorrectos.", "error");
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Acceso al Panel de Administración</h2>
      <form onSubmit={handleLogin} className="mt-4">
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Correo Electrónico
          </label>
          <input
            type="email"
            id="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Contraseña
          </label>
          <input
            type="password"
            id="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Iniciar Sesión
        </button>
      </form>
    </div>
  );
};

export default Login;
