import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import "./ArtworkDetail.css";

const ArtworkDetail = () => {
  const { id } = useParams();
  const [artwork, setArtwork] = useState(null);

  useEffect(() => {
    const fetchArtwork = async () => {
      try {
        const docRef = doc(db, "artworks", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setArtwork(docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching artwork:", error);
      }
    };
    fetchArtwork();
  }, [id]);

  if (!artwork)
    return (
      <div className="container loading-message">
        <p>Cargando...</p>
      </div>
    );

  return (
    <div className="artwork-detail container">
      <div className="artwork-content">
        <h1>{artwork.title}</h1>
        <img src={artwork.image} alt={artwork.title} />
        <p>{artwork.description}</p>
      </div>
    </div>
  );
};

export default ArtworkDetail;
