import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import Swal from "sweetalert2";

const AddExperience = () => {
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [experiences, setExperiences] = useState([]);
  const [editId, setEditId] = useState(null);

  // Fetch experiences from Firestore
  const fetchExperiences = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "experiences"));
      const fetchedExperiences = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date.toDate(), // Convertir el campo date a un objeto Date
      }));
      setExperiences(fetchedExperiences);
    } catch (error) {
      console.error("Error al obtener las experiencias:", error);
    }
  };

  useEffect(() => {
    fetchExperiences();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!date || !name || !country || !state) {
      Swal.fire("Error", "Todos los campos son obligatorios.", "error");
      return;
    }

    try {
      if (editId) {
        // Edit existing experience
        const experienceRef = doc(db, "experiences", editId);
        await updateDoc(experienceRef, { date: new Date(date), name, country, state });
        Swal.fire("Éxito", "Experiencia actualizada correctamente.", "success");
      } else {
        // Add new experience
        await addDoc(collection(db, "experiences"), {
          date: new Date(date),
          name,
          country,
          state,
        });
        Swal.fire("Éxito", "Experiencia añadida correctamente.", "success");
      }

      resetForm();
      fetchExperiences();
    } catch (error) {
      Swal.fire("Error", "No se pudo guardar la experiencia.", "error");
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás deshacer esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "experiences", id));
          Swal.fire("Eliminada", "La experiencia ha sido eliminada.", "success");
          fetchExperiences();
        } catch (error) {
          Swal.fire("Error", "No se pudo eliminar la experiencia.", "error");
        }
      }
    });
  };

  // Handle edit
  const handleEdit = (experience) => {
    setDate(experience.date.toISOString().split("T")[0]); // Convertir a formato YYYY-MM-DD
    setName(experience.name);
    setCountry(experience.country);
    setState(experience.state);
    setEditId(experience.id);
  };

  // Reset form
  const resetForm = () => {
    setDate("");
    setName("");
    setCountry("");
    setState("");
    setEditId(null);
  };

  return (
    <div className="container mt-5">
      <h2>Agregar Experiencia</h2>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-3">
          <label htmlFor="date" className="form-label">
            Fecha
          </label>
          <input
            type="date"
            id="date"
            className="form-control"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nombre de la Experiencia
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="country" className="form-label">
            País
          </label>
          <input
            type="text"
            id="country"
            className="form-control"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="state" className="form-label">
            Provincia/Estado
          </label>
          <input
            type="text"
            id="state"
            className="form-control"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary me-2">
          {editId ? "Actualizar Experiencia" : "Agregar Experiencia"}
        </button>
        <button type="button" className="btn btn-secondary" onClick={resetForm}>
          Cancelar
        </button>
      </form>

      {/* List of Experiences */}
      <h3 className="mt-5">Lista de Experiencias</h3>
      <table className="table table-striped mt-3">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Nombre</th>
            <th>País</th>
            <th>Provincia/Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {experiences.map((experience) => (
            <tr key={experience.id}>
              <td>{experience.date.toLocaleDateString()}</td>
              <td>{experience.name}</td>
              <td>{experience.country}</td>
              <td>{experience.state}</td>
              <td>
                <button
                  className="btn btn-warning btn-sm me-2"
                  onClick={() => handleEdit(experience)}
                >
                  Editar
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDelete(experience.id)}
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddExperience;
