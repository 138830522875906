import React, { useState, useEffect } from "react";
import { db, storage } from "../firebaseConfig";
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const Blog = () => {
  const [entries, setEntries] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [copete, setCopete] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [editId, setEditId] = useState(null);

  // Obtener las entradas del blog desde Firestore
  const fetchEntries = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "latestExhibition"));
      const fetchedEntries = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => (a.order || 0) - (b.order || 0)); // Ordenar según el campo 'order'
      setEntries(fetchedEntries);
    } catch (error) {
      console.error("Error al obtener entradas del blog:", error);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleAddOrEditEntry = async (e) => {
    e.preventDefault();

    if (!title || !subtitle || !copete || !body || (!image && !editId)) {
      Swal.fire("Error", "Todos los campos son obligatorios.", "error");
      return;
    }

    try {
      let imageUrl = preview;

      // Subir la imagen si hay una nueva seleccionada
      if (image) {
        const imageRef = ref(storage, `blog/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      if (editId) {
        // Actualizar la entrada existente
        const entryRef = doc(db, "latestExhibition", editId);
        await updateDoc(entryRef, { title, subtitle, copete, body, image: imageUrl });
        Swal.fire("Éxito", "Entrada actualizada correctamente.", "success");
      } else {
        // Crear una nueva entrada
        await addDoc(collection(db, "latestExhibition"), {
          title,
          subtitle,
          copete,
          body,
          image: imageUrl,
          showInHome: false,
          order: entries.length + 1, // Asignar el siguiente orden
          date: new Date().toISOString(),
        });
        Swal.fire("Éxito", "Entrada de blog añadida correctamente.", "success");
      }

      resetForm();
      fetchEntries();
    } catch (error) {
      console.error("Error al agregar o editar la entrada:", error);
      Swal.fire("Error", "No se pudo procesar la entrada.", "error");
    }
  };

  const handleDeleteEntry = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás deshacer esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "latestExhibition", id));
          Swal.fire("Eliminada", "La entrada ha sido eliminada.", "success");
          fetchEntries();
        } catch (error) {
          Swal.fire("Error", "No se pudo eliminar la entrada.", "error");
        }
      }
    });
  };

  const handleEditEntry = (entry) => {
    setTitle(entry.title);
    setSubtitle(entry.subtitle);
    setCopete(entry.copete);
    setBody(entry.body);
    setPreview(entry.image);
    setEditId(entry.id);
  };

  const handleShowInHomeToggle = async (id, showInHome) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: showInHome
        ? "Esta entrada se mostrará en el Home."
        : "Esta entrada ya no se mostrará en el Home.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, confirmar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const entryRef = doc(db, "latestExhibition", id);
          await updateDoc(entryRef, { showInHome });
          Swal.fire(
            "Actualizado",
            showInHome
              ? "La entrada se muestra en el Home."
              : "La entrada ya no se muestra en el Home.",
            "success"
          );
          fetchEntries();
        } catch (error) {
          console.error("Error al actualizar la visualización en home:", error);
          Swal.fire("Error", "No se pudo actualizar la visualización.", "error");
        }
      }
    });
  };

  const resetForm = () => {
    setTitle("");
    setSubtitle("");
    setCopete("");
    setBody("");
    setImage(null);
    setPreview(null);
    setEditId(null);
  };

  return (
    <div className="container mt-5">
      <h2>Gestión de Blog</h2>
      <form onSubmit={handleAddOrEditEntry} className="mt-4">
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Título
          </label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subtitle" className="form-label">
            Subtítulo
          </label>
          <input
            type="text"
            id="subtitle"
            className="form-control"
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="copete" className="form-label">
            Copete
          </label>
          <textarea
            id="copete"
            className="form-control"
            value={copete}
            onChange={(e) => setCopete(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="body" className="form-label">
            Cuerpo
          </label>
          <ReactQuill
            id="body"
            value={body}
            onChange={setBody}
            className="form-control"
            theme="snow"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="form-label">
            Imagen
          </label>
          <input
            type="file"
            id="image"
            className="form-control"
            accept="image/*"
            onChange={handleImageChange}
          />
          {preview && (
            <div className="mt-3">
              <p>Vista previa:</p>
              <img src={preview} alt="Preview" style={{ maxWidth: "100%", height: "auto" }} />
            </div>
          )}
        </div>
        <button type="submit" className="btn btn-primary">
          {editId ? "Actualizar Entrada" : "Agregar Entrada"}
        </button>
        <button type="button" className="btn btn-secondary ms-2" onClick={resetForm}>
          Cancelar
        </button>
      </form>

      <h3 className="mt-5">Lista de Entradas</h3>
      <div className="blog-grid">
        {entries.map((entry) => (
          <div key={entry.id} className="blog-card">
            <div
              className="blog-image"
              style={{
                backgroundImage: `url(${entry.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="blog-content">
              <h3>{entry.title}</h3>
              <p>{entry.subtitle}</p>
              <p>{entry.copete}</p>
              <input
                type="checkbox"
                checked={entry.showInHome || false}
                onChange={(e) => handleShowInHomeToggle(entry.id, e.target.checked)}
              />
              <button className="btn btn-warning btn-sm" onClick={() => handleEditEntry(entry)}>
                Editar
              </button>
              <button className="btn btn-danger btn-sm" onClick={() => handleDeleteEntry(entry.id)}>
                Eliminar
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
