import React from "react";
import { Link } from "react-router-dom";
//import "./AdminPanel.css";

const AdminPanel = () => {
  return (
    <div className="admin-panel">
      <h1>Panel de Administración</h1>
      <ul className="admin-menu">
        <li>
          <Link to="/admin/add-artwork">Agregar Obras</Link>
        </li>
        <li>
          <Link to="/admin/blog">Gestionar Blog</Link>
        </li>
        <li>
          <Link to="/admin/add-experience">Agregar Experiencia</Link>
        </li>
        <li>
          <Link to="/admin/upload-3d">Subir Modelo 3D</Link>
        </li>
        <li>
          <Link to="/admin/new-arrivals">Agregar Lo Nuevo</Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminPanel;
