import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";

const About = () => {
  const [experiences, setExperiences] = useState([]);

  // Cargar datos de la colección `experiences`
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "experiences"), (snapshot) => {
      const fetchedExperiences = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Ordenar las experiencias por fecha descendente
      const sortedExperiences = fetchedExperiences.sort(
        (a, b) => b.date.seconds - a.date.seconds
      );
      setExperiences(sortedExperiences);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="about container mt-5">
      <h2>Sobre Mí</h2>
      <p>Explora mi experiencia y trayectoria profesional en el área artística.</p>
      <table className="table table-striped mt-4">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Nombre de la Experiencia</th>
            <th>País</th>
            <th>Provincia/Estado</th>
          </tr>
        </thead>
        <tbody>
          {experiences.map((exp) => (
            <tr key={exp.id}>
              <td>{new Date(exp.date.seconds * 1000).toLocaleDateString()}</td>
              <td>{exp.name}</td>
              <td>{exp.country}</td>
              <td>{exp.state}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default About;
