import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "./Home.css";

const Home = () => {
  const [artworks, setArtworks] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);

  const sliderImages = [
    { src: process.env.PUBLIC_URL + "/images/foto1.jpeg", text: "Explora la belleza del arte contemporáneo." },
    { src: process.env.PUBLIC_URL + "/images/foto2.jpeg", text: "Cada escultura cuenta una historia única." },
    { src: process.env.PUBLIC_URL + "/images/foto3.jpeg", text: "Sumérgete en el mundo de la creatividad." },
    { src: process.env.PUBLIC_URL + "/images/foto4.jpeg", text: "El arte es la música visual del alma." },
  ];

  useEffect(() => {
    // Obtener obras destacadas
    const unsubscribeArtworks = onSnapshot(collection(db, "artworks"), (snapshot) => {
      const fetchedArtworks = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((artwork) => artwork.showInHome);
      setArtworks(fetchedArtworks);
    });

    // Obtener entradas del blog
    const unsubscribeBlogPosts = onSnapshot(collection(db, "latestExhibition"), (snapshot) => {
      const fetchedPosts = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((post) => post.showInHome);
      setBlogPosts(fetchedPosts);
    });

    // Obtener lo nuevo
    const unsubscribeNewArrivals = onSnapshot(collection(db, "newArrivals"), (snapshot) => {
      const fetchedNewArrivals = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNewArrivals(fetchedNewArrivals);
    });

    return () => {
      unsubscribeArtworks();
      unsubscribeBlogPosts();
      unsubscribeNewArrivals();
    };
  }, []);

  return (
    <div className="home">
      {/* Slider Section */}
      <section className="slider">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop={true}
          className="slider-container"
        >
          {sliderImages.map((slide, index) => (
            <SwiperSlide key={index}>
              <div
                className="slider-item"
                style={{
                  backgroundImage: `url(${slide.src})`,
                  height: "60vh",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="slider-text">
                  <p>{slide.text}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <div className="main-content">
        <div className="main-grid">
          {/* Artwork Section */}
          <section className="artwork-section">
            <h2 className="section-title">Obras Destacadas</h2>
            <div className="artwork-grid">
              {artworks.length > 0 ? (
                artworks.map((artwork) => (
                  <div key={artwork.id} className="artwork-card">
                    <div
                      className="artwork-image"
                      style={{
                        backgroundImage: `url(${artwork.image})`,
                      }}
                    ></div>
                    <div className="artwork-info">
                      <h3>{artwork.title}</h3>
                      <p>{artwork.description.slice(0, 100)}...</p>
                      <Link to={`/artwork/${artwork.id}`} className="read-more">
                        Leer más
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-artworks">No hay obras destacadas disponibles.</p>
              )}
            </div>
          </section>

          {/* Blog Section */}
          <section className="blog-section">
            <h2 className="section-title">Últimas Entradas del Blog</h2>
            <div className="blog-grid">
              {blogPosts.length > 0 ? (
                blogPosts.map((post) => (
                  <div key={post.id} className="blog-card">
                    <div
                      className="blog-image"
                      style={{
                        backgroundImage: `url(${post.image})`,
                      }}
                    ></div>
                    <div className="blog-content">
                      <h3>{post.title}</h3>
                      <p>{post.subtitle}</p>
                      <Link to={`/blog/${post.id}`} className="read-more">
                        Leer más
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-posts">No hay entradas disponibles.</p>
              )}
            </div>
          </section>
        </div>

        {/* Sidebar for New Arrivals */}
        <aside className="sidebar">
          <h2 className="sidebar-title">Lo Nuevo</h2>
          <div className="new-arrivals-grid">
            {newArrivals.length > 0 ? (
              newArrivals.map((item) => (
                <div key={item.id} className="new-arrival-card">
                  <Link to={`/new/${item.id}`}>
                    <img src={item.image} alt={item.title} className="new-arrival-image" />
                    <p className="new-arrival-title">{item.title}</p>
                  </Link>
                </div>
              ))
            ) : (
              <p>No hay novedades disponibles.</p>
            )}
          </div>
        </aside>
      </div>

      {/* Video Section */}
      <section className="video-section">
        <h2 className="section-title">Explora Nuestro Arte</h2>
        <video controls className="promo-video">
          <source src={process.env.PUBLIC_URL + "/videos/promo.mp4"} type="video/mp4" />
          Tu navegador no soporta videos HTML5.
        </video>
      </section>
    </div>
  );
};

export default Home;
