import React from "react";

const ArtworkCard = ({ artwork }) => (
  <div className="artwork-card">
    <img src={artwork.image} alt={artwork.title} />
    <h3>{artwork.title}</h3>
    <p>{artwork.description}</p>
  </div>
);

export default ArtworkCard;
