import React from "react";
import "./Contact.css";
import catalogPdf from "../assets/Catalogocarinafabaro.pdf"; // Cambia esta ruta según la ubicación del archivo

const Contact = () => (
  <div className="contact">
    <h2>Contacto</h2>
    <div className="contact-content">
      <img
        src={`${process.env.PUBLIC_URL}/images/contact-image.jpg`} // Asegúrate de colocar la imagen en la carpeta `public/images`
        alt="Carina Fabaro"
        className="contact-image"
      />
      <div className="contact-details">
        <p>Email: carina</p>
        <p>Teléfono: +54 9 380 4649529</p>
        <a href={catalogPdf} download className="download-button">
          Descargar Catálogo
        </a>
      </div>
    </div>
  </div>
);

export default Contact;
